@import 'bootstrap_dark';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~@forevolve/bootstrap-dark/scss/_dark-variables.scss';

$alert-cell-color: $white;
$rule-cell-bg: $gray-900;

$config-yaml-color: $black;
$config-yaml-bg: $gray-500;
$config-yaml-border: $gray-700;

$query-stats-color: lighten($secondary, 20%);

$metrics-explorer-bg: $dropdown-link-hover-bg;

$clear-time-btn-bg: $secondary;

$checked-checkbox-color: #60a5fa;

.bootstrap-dark {
  @import './shared';
}
